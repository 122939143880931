const views = {
  home: 'home',
  manage: 'manage',
  plans: 'plans',
  pest: 'pest',
  agroanalysis: 'agroanalysis',
  analysis: 'analysis',
  analysisDetail: 'analysisDetail'
};

export default [
  {
    path: '/',
    name: 'Home',
    component: views.home
  },
  {
    path: '/riego',
    name: 'Riego',
    component: views.home
  },
  {
    path: '/fertilizacion',
    name: 'Fertilizacion',
    component: views.home
  },
  {
    path: '/agroanalisis',
    name: 'Agroanalisis',
    component: views.agroanalysis
  },
  {
    path: '/tiempo',
    name: 'Tiempo',
    component: views.home
  },
  {
    path: '/plagas',
    name: 'Plagas',
    component: views.pest
  },
  {
    path: '/cultivos-finalizados',
    name: 'FinishedCrops',
    component: views.home
  },
  {
    path: '/analisis/edicion',
    name: 'AnalisisEdicion',
    component: views.analysis
  },
  {
    path: '/analisis/:analisis',
    name: 'Analisis',
    component: views.analysisDetail,
    children: [
      {
        path: 'agua',
        name: 'agua',
        component: views.analysisDetail
      },
      {
        path: 'suelo',
        name: 'suelo',
        component: views.analysisDetail
      }
    ]
  },
  {
    path: '/planes/:plan',
    name: 'Planes',
    component: views.plans,
    children: [
      {
        path: 'riego',
        name: 'riego',
        component: views.plans
      },
      {
        path: 'fertilizacion',
        name: 'fertilizacion',
        component: views.plans
      }
    ]
  },
  {
    path: '/gestion/:step',
    name: 'Gestion',
    component: views.manage,
    children: [
      {
        path: 'explotacion',
        name: 'explotacion',
        component: views.manage
      },
      {
        path: 'finca',
        name: 'finca',
        component: views.manage
      },
      {
        path: 'cultivo',
        name: 'cultivo',
        component: views.manage
      }
    ]
  }
];
