import { injectScript } from '../helpers/script';
// Llamar al hsforms https://js.hsforms.net/forms/shell.js solo cuando sea necesario en la página que se necesite
export default (environment, hubspotTrackingCode) => {
  if (environment === 'production' && hubspotTrackingCode) {
    const hubspotUrl = `https://js.hs-scripts.com/${hubspotTrackingCode}.js`;
    injectScript(hubspotUrl, '_hsp', true, {
      id: 'hs-script-loader',
      async: true,
      defer: true
    }).then(() => {
      // Hacer lo que se necesite
    });
  }
};
