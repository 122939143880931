export default enums => {
  if ('serviceWorker' in navigator) {
    window.addEventListener('single-spa:first-mount', () => {
      navigator.serviceWorker
        .register('sw.js')
        .then(reg => {
          fetch(`${self.location.origin}/manifest.json`)
            .then(res => res.json())
            .then(res => {
              const currentVersion = res.version;
              const storageVersion = enums.STORAGE_VERSION_SERVICE_WORKER;
              if (localStorage.getItem(storageVersion) !== currentVersion) {
                localStorage.setItem(storageVersion, currentVersion);
                // eslint-disable-next-line max-nested-callbacks
                reg.unregister().then(() => window.location.reload());
              }
            });
          localStorage.setItem(enums.STATUS_SERVICE_WORKER, true);
          window.dispatchEvent(new Event('service-worker'));
        })
        .catch(err => console.warn('service worker not registered', err));
    });
  }
};
