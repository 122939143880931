export default (environment, gtmId) => {
  if (environment === 'production' && gtmId) {
    // Add script in head
    const el = document.createElement('script');
    const gtmInlineScript =
      document.createTextNode(`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer', '${gtmId}');`);
    el.appendChild(gtmInlineScript);
    document.head.appendChild(el);
  }
};
