import { start } from 'single-spa';
import { events } from '@pt/utilities';
import { auth } from '@pt/services';

export const authEventsOn = enums => {
  // The user has canceled the entry of information affirmed by himself
  events.on(enums.EVT_AUTH_USER_CANCELED_ENTRY, () => {
    auth.signIn();
  });

  // Event in action authenticated user
  events.on(enums.EVT_AUTH_AUTHENTICATED_USER, () => {
    // Remove the loader when user authenticated in app
    const elementLoading = document.querySelector('.sk-container');
    if (elementLoading) elementLoading.parentNode.removeChild(elementLoading);
    start({ urlRerouteOnly: true });
  });
};

export const authCheckSignIn = env => {
  const regExpSigninCallbackUri = new RegExp(env.APP_MS_REDIRECT_URI);
  const checkSigninCallbackUri = regExpSigninCallbackUri.test(window.location.pathname);
  const checkReferrerSigninCallbackUri = regExpSigninCallbackUri.test(document.referrer);

  if (!auth.getActiveAccount() && !checkSigninCallbackUri && !checkReferrerSigninCallbackUri) {
    auth.signIn();
  }
};
