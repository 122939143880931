import { registerApplication } from 'single-spa';
import routesList from './routes';
import gtm from './scripts/gtm';
import hubspot from './scripts/hubspot';
import { authEventsOn, authCheckSignIn } from './scripts/authentication';
import workers from './scripts/service-workers';

import { constructApplications, constructRoutes, constructLayoutEngine } from 'single-spa-layout';
import microfrontendLayout from './microfrontend-layout.html';

System.import('@pt/env-config').then(pkg => {
  const { env, enums } = pkg;
  // Service workers
  workers(enums);
  // Google Tag Manager
  gtm(env.APP_ENVIRONMENT, env.APP_GTM_ID);
  // Hubspot live chat
  hubspot(env.APP_ENVIRONMENT, env.APP_HUBSPOT_TRACKING_CODE);

  System.import('@pt/services').then(async ({ auth }) => {
    authEventsOn(enums);
    await auth.initialize();
    authCheckSignIn(env);
  });
});

const routes = constructRoutes(microfrontendLayout);
const applications = constructApplications({
  routes,
  loadApp({ name }) {
    return System.import(name);
  }
}).map(app => ({ ...app, customProps: { routesList } }));
const layoutEngine = constructLayoutEngine({ routes, applications });
applications.forEach(registerApplication);
layoutEngine.activate();
